import { Button, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import error from "../assets/images/error-404-rouge.svg";

export default function Error() {
  return (
    <div className="w-full h-full flex flex-col items-center justify-center errorPage">
      <h1>Erreur 404</h1>
      <h2 className="mb-[24px]">Page non trouvée</h2>
      <img
        src={error}
        alt="Erreur 404 : Page non trouvée"
        width={850}
        style={{ transform: "rotate(-5deg)" }}
      />
      <p className="text-3xl ScaniaSansHeadlineBold">Oups ! </p>
      <Typography sx={{ mt: "4px", pb: "24px", textAlign: "center" }}>
        Il semble que la page que vous recherchez soit introuvable.
      </Typography>
      <Button
        to="/dashboard"
        component={Link}
        variant="contained"
        color="secondary"
        className="scania-button"
        sx={{ mr: 1 }}
      >
        RETOUR SUR MON DASHBOARD
      </Button>
    </div>
  );
}
