// NotificationContext.js
import React, { createContext, useCallback, useContext, useRef } from "react";
import { Bounce, toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import notifSound from "../assets/notif.mp3";
import { useNavigate } from "react-router-dom";

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const audioRef = useRef(null);
  const navigate = useNavigate();

  const playSound = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  const showToast = useCallback(
    (message, { state = "info", duration = 5000, redirectUrl = null } = {}) => {
      const toastOptions = {
        position: "bottom-right",
        autoClose: duration,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
        transition: Bounce,
        onClick: () => {
          if (redirectUrl) {
            navigate(redirectUrl);
          }
        },
      };

      switch (state) {
        case "success":
          toast.success(message, toastOptions);
          break;
        case "warning":
          toast.warn(message, toastOptions);
          break;
        case "error":
          toast.error(message, toastOptions);
          break;
        case "default":
          toast(message, toastOptions);
          break;
        case "info":
        default:
          toast.info(message, toastOptions);
          break;
      }
    },
    []
  );

  return (
    <NotificationContext.Provider value={showToast}>
      {children}
      <div className="absolute">
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover
          theme="light"
          transition={Bounce}
        />
      </div>
      <audio ref={audioRef} src={notifSound} preload="auto">
        <track
          kind="captions"
          src="path_to_captions.vtt"
          srcLang="en"
          label="English"
          default
        />
      </audio>
    </NotificationContext.Provider>
  );
};

export const useNotification = () => {
  return useContext(NotificationContext);
};
