// UserContext.js
import React, { createContext, useContext, useEffect, useState } from "react";
import { useMemo } from "react";

// Créez un contexte pour le panier
const UserContext = createContext();

export const UserProvider = ({ children }) => {
  // Initialise le state user avec les données stockées dans le local storage ou null si non existant
  const [user, setUser] = useState(() => {
    const storedUser = localStorage.getItem("w2p-bo-admin");
    return storedUser ? JSON.parse(storedUser) : null;
  });

  // Fonction pour mettre à jour les informations de l'utilisateur
  const updateUser = (userData) => {
    setUser(userData);
  };

  useEffect(() => {
    // Stocke les données dans le local storage chaque fois que le state user change
    localStorage.setItem("w2p-bo-admin", JSON.stringify(user));
  }, [user]);

  // Utilise useMemo pour éviter de recréer le contexte à chaque rendu
  const contextValue = useMemo(
    () => ({
      user,
      setUser: updateUser,
    }),
    [user]
  );

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};
