import { lazy } from "react";
const MainLayout = lazy(() => import("../layouts/MainLayout"));
const AuthGuard = lazy(() => import("./AuthGuard"));
const GestionComptes = lazy(() =>
  import("../pages/gestionnaire/accounts/AccountsList")
);
const GestionCommandes = lazy(() =>
  import("../pages/gestionnaire/GestionCommandes")
);
const GestionCommandeDetails = lazy(() =>
  import("../pages/gestionnaire/GestionCommandeDetails")
);

const requiredRole = "gestionnaire";

const GestionnaireRoutes = {
  path: "gestionnaire",
  element: <MainLayout />,
  children: [
    {
      path: "gestion-comptes",
      element: (
        <AuthGuard requiredRole={requiredRole}>
          <GestionComptes />
        </AuthGuard>
      ),
    },
    {
      path: "commandes",
      element: (
        <AuthGuard requiredRole={requiredRole}>
          <GestionCommandes />
        </AuthGuard>
      ),
    },
    {
      path: "commandes/:ref",
      element: (
        <AuthGuard requiredRole={requiredRole}>
          <GestionCommandeDetails />
        </AuthGuard>
      ),
    },
  ],
};

export default GestionnaireRoutes;
