import { lazy } from "react";

const MainLayout = lazy(() => import("../layouts/MainLayout"));

const GestionComptes = lazy(() =>
  import("../pages/super-admin/accounts/AccountsList")
);
const ProductList = lazy(() => import("../pages/super-admin/shop/ProductList"));
const GridPrice = lazy(() => import("../pages/super-admin/gridPrice"));
const GridPriceShipping = lazy(() =>
  import("../pages/super-admin/gridShippingPrice")
);
const GridPricePackaging = lazy(() =>
  import("../pages/super-admin/gridPackagingPrice")
);
const AddProduct = lazy(() => import("../pages/super-admin/shop/AddProduct"));
const AuthGuard = lazy(() => import("./AuthGuard"));
const FormRouter = lazy(() =>
  import("../pages/super-admin/shop/productForms/FormRouter")
);
const Features = lazy(() => import("../pages/public/Features"));
const Structure = lazy(() => import("../pages/Structure"));
const Factures = lazy(() => import("../pages/super-admin/Factures"));
const GestionCommandes = lazy(() =>
  import("../pages/super-admin/GestionCommandes")
);
const GestionCommandeDetails = lazy(() =>
  import("../pages/super-admin/GestionCommandeDetails")
);
const FormUpdateRouter = lazy(() =>
  import("../pages/super-admin/shop/productForms/FormUpdateRouter")
);

const requiredRole = "super-admin";

const SuperAdminRoutes = {
  path: "super-admin",
  element: <MainLayout />,
  children: [
    {
      index: true,
      element: (
        <AuthGuard>
          <Features />
        </AuthGuard>
      ),
    },
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <Features />
        </AuthGuard>
      ),
    },
    {
      path: "gestion-comptes",
      element: (
        <AuthGuard requiredRole={requiredRole}>
          <GestionComptes />
        </AuthGuard>
      ),
    },
    {
      path: "gestion-produits",
      element: (
        <AuthGuard>
          <ProductList />
        </AuthGuard>
      ),
    },
    {
      path: "gestion-produits/ajout",
      element: (
        <AuthGuard>
          <AddProduct />
        </AuthGuard>
      ),
    },
    {
      path: "gestion-produits/ajout/:type",
      element: (
        <AuthGuard>
          <FormRouter />
        </AuthGuard>
      ),
    },
    {
      path: "gestion-produits/modification/:type/:slug",
      element: (
        <AuthGuard>
          <FormUpdateRouter />
        </AuthGuard>
      ),
    },
    {
      path: "gestion-commandes",
      element: (
        <AuthGuard requiredRole={requiredRole}>
          <GestionCommandes />
        </AuthGuard>
      ),
    },
    {
      path: "commandes/:ref",
      element: (
        <AuthGuard requiredRole={requiredRole}>
          <GestionCommandeDetails />
        </AuthGuard>
      ),
    },
    {
      path: "gestion-tarifs",
      element: (
        <AuthGuard>
          <GridPrice />
        </AuthGuard>
      ),
    },
    {
      path: "gestion-tarifs-livraison",
      element: (
        <AuthGuard>
          <GridPriceShipping />
        </AuthGuard>
      ),
    },
    {
      path: "gestion-tarifs-conditionnement",
      element: (
        <AuthGuard>
          <GridPricePackaging />
        </AuthGuard>
      ),
    },
    {
      path: "gestion-tarifs/ajout",
      element: (
        <AuthGuard>
          <GridPrice />
        </AuthGuard>
      ),
    },
    {
      path: "gestion-sites",
      element: (
        <AuthGuard requiredRole={requiredRole}>
          <Structure />
        </AuthGuard>
      ),
    },
    {
      path: "factures",
      element: (
        <AuthGuard>
          <Factures />
        </AuthGuard>
      ),
    },
  ],
};

export default SuperAdminRoutes;
