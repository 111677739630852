import { useLocation, useRoutes } from "react-router-dom";
import * as React from "react";

// routes
import AuthenticationRoutes from "./AuthenticationRoutes";
import MainRoutes from "./MainRoutes";
import SuperAdminRoutes from "./SuperAdminRoutes";
import GestionnaireRoutes from "./GestionnaireRoutes";
import Error from "../pages/Error";
import AdminRoutes from "./AdminRoutes";
import { AnimatePresence } from "framer-motion";

export default function ThemeRoutes() {
  const element = useRoutes([
    AuthenticationRoutes,
    MainRoutes,
    AdminRoutes,
    SuperAdminRoutes,
    GestionnaireRoutes,
    {
      path: "*",
      element: <Error />,
    },
  ]);

  const location = useLocation();

  if (!element) return null;

  return (
    <AnimatePresence mode="wait">
      {React.cloneElement(element, { key: location.pathname })}
    </AnimatePresence>
  );
}
