import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../utils/axiosConfig";
import { useUser } from "../contexts/UserContext";

// CE COMPOSANT ASSURE QUE LE USER POSSEDE BIEN LE ROLE REQUIS POUR LA PAGE DEMANDÉE
// SINON, ON REDIRIGE AILLEURS

export default function AuthGuard({ children, requiredRole }) {
  const navigate = useNavigate();
  const { setUser } = useUser();

  useEffect(() => {
    axiosInstance("/api/user/check-role", {
      method: "POST",
      credentials: "include",
      data: { requiredRole },
    })
      .then((response) => {
        if (response.status !== 200) {
          setUser({});
          return navigate("/connexion", { replace: true });
        }
      })
      .catch((error) => {
        axiosInstance({
          method: "get",
          url: `/api/user/logout`,
        })
          .then((res) => {
            navigate("/connexion", { replace: true });
            setUser({});
          })
          .catch((err) => {
            console.log(err);
          });
      });
  }, [navigate, requiredRole, setUser]);

  return children;
}
