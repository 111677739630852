import { CircularProgress } from "@mui/material";
import React, { Suspense } from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import config from "./config";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { store } from "./store";

render(
  <Provider store={store}>
    <BrowserRouter basename={config.basename}>
      <Suspense
        fallback={
          <div className="w-full h-screen flex justify-center items-center">
            <CircularProgress />
          </div>
        }
      >
        <App />
      </Suspense>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

serviceWorkerRegistration.register();
