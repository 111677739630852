import { lazy } from "react";

const AuthLayout = lazy(() =>
  import("../layouts/AuthLayout")
);

const Login = lazy(() => import("../pages/Login"));
const ForgotPassword = lazy(() => import("../pages/ForgotPassword"));
const ResetPassword = lazy(() => import("../pages/ResetPassword"));
const ConfirmationSignUp = lazy(() => import("../pages/ConfirmationSignUp"));

const AuthenticationRoutes = {
  path: "/",
  element: <AuthLayout />,
  children: [
    {
      path: "connexion",
      element: <Login />,
    },
    {
      path: "/finalisation-inscription/:token",
      element: <ConfirmationSignUp />,
    },
    {
      path: "mot-passe-oublie",
      element: <ForgotPassword />,
    },
    {
      path: "reinitialiser-mot-de-passe/:token",
      element: <ResetPassword />,
    },
  ],
};

export default AuthenticationRoutes;
