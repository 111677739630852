import { ThemeProvider, createTheme } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { CartProvider } from "./contexts/CartContext";
import { UserProvider } from "./contexts/UserContext";
import "./styles/_index.scss";
import { themeSettings } from "./theme";

// routing
import { HelmetProvider } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import ErrorBoundary from "./components/utils/ErrorBoundary";
import { NotificationProvider } from "./contexts/NotifContext";
import Routes from "./routes";
import { setCookie } from "./utils/CheckCookie";

const App = () => {
  const location = useLocation();

  // Mise à jour de Google Consent Mode selon les préférences de l'utilisateur
  function gtag() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(arguments);
  }

  useEffect(() => {
    let cookieConsent;
    try {
      if (cookieConsent && process.env.NODE_ENV === "production") {
        // Valeurs par défaut pour Google Consent Mode avant récupération du consentement
        gtag("consent", "default", {
          analytics_storage: "denied",
          ad_storage: "denied",
          ad_user_data: "denied",
          ad_personalization: "denied",
          personalization_storage: "denied",
          functionality_storage: "granted", // Les fonctionnalités de base sont toujours activées
          security_storage: "granted", // Les fonctionnalités de sécurité sont toujours activées
        });

        // Tentative de récupération du consentement de l'utilisateur depuis le cookie
        // cookieConsent = JSON.parse(getCookie("cookieConsent"));
        cookieConsent = {
          analytics_storage: "granted",
          ad_storage: "granted",
          ad_user_data: "granted",
          ad_personalization: "granted",
          personalization_storage: "granted",
          functionality_storage: "granted",
          security_storage: "granted",
        };

        // Mise à jour de Google Consent Mode avec les préférences de l'utilisateur
        gtag("consent", "update", {
          analytics_storage: cookieConsent.analytics_storage
            ? "granted"
            : "denied",
          ad_storage: cookieConsent.ad_storage ? "granted" : "denied",
          ad_user_data: cookieConsent.ad_user_data ? "granted" : "denied",
          ad_personalization: cookieConsent.ad_personalization
            ? "granted"
            : "denied",
          personalization_storage: cookieConsent.personalization_storage
            ? "granted"
            : "denied",
          functionality_storage: "granted", // Supposant que cela reste toujours activé
          security_storage: "granted", // Supposant que cela reste toujours activé
        });

        // Suivi de la pageview si le consentement pour les analytics est accordé
        if (cookieConsent.analytics_storage) {
          gtag("event", "pageview", {
            page_path: location.pathname + location.search,
          });
        }
      }
    } catch (error) {
      console.error("Error reading or updating cookie consent:", error);
      setCookie("cookieConsent", null, 0); // Réinitialisation du consentement en cas d'erreur
    }
  }, [location]);

  const isSmallScreen = window.innerWidth <= 767;
  const theme = useMemo(() => createTheme(themeSettings("light")), ["light"]);

  return (
    <HelmetProvider>
      <ErrorBoundary>
        <ThemeProvider theme={theme}>
          <UserProvider>
            <CartProvider>
              <NotificationProvider>
                {!isSmallScreen ? (
                  <Routes />
                ) : (
                  <div
                    style={{
                      height: "100%",
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <h1
                      style={{
                        textAlign: "center",
                        marginBottom: "20px",
                        width: "80%",
                      }}
                    >
                      Bienvenue sur Scania Web2Print !
                    </h1>
                    <p
                      style={{
                        textAlign: "center",
                        marginBottom: "10px",
                        width: "80%",
                      }}
                    >
                      Malheureusement, l'application n'est pas disponible sur
                      mobile 😢
                    </p>
                    <p
                      style={{
                        textAlign: "center",
                        width: "80%",
                      }}
                    >
                      Veuillez visiter cette URL via un ordinateur ou une
                      tablette (au format paysage) 💻
                    </p>
                  </div>
                )}
              </NotificationProvider>
            </CartProvider>
          </UserProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </HelmetProvider>
  );
};

export default App;
