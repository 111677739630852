// CartContext.js
import React, { createContext, useContext, useReducer, useEffect } from "react";

// Créez un contexte pour le panier
const CartContext = createContext();

// Définissez l'action type pour SET_CART
const SET_CART = "SET_CART";

// Créez un réducteur pour gérer les actions du panier
const cartReducer = (state, action) => {
  switch (action.type) {
    case SET_CART:
      return action.payload; // Remplacez tout le contenu du panier
    case "INIT_CART":
      const initialCart = action.payload; // Ajoutez un produit à la liste existante
      localStorage.setItem("W2P_Cart", JSON.stringify(initialCart));
      return initialCart;
    case "ADD_TO_CART":
      const updatedCart = [...state, action.payload]; // Ajoutez un produit à la liste existante
      localStorage.setItem("W2P_Cart", JSON.stringify(updatedCart));
      return updatedCart;
    default:
      return state;
  }
};

export const CartProvider = ({ children }) => {
  // Récupérez le panier depuis le localStorage lors du lancement
  const initialCart = JSON.parse(localStorage.getItem("W2P_Cart")) || [];

  const [cart, dispatch] = useReducer(cartReducer, initialCart);

  // Utilisez useEffect pour stocker le panier dans le localStorage à chaque mise à jour
  useEffect(() => {
    localStorage.setItem("W2P_Cart", JSON.stringify(cart));
  }, [cart]);

  return (
    <CartContext.Provider value={{ cart, dispatch }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  return useContext(CartContext);
};
