import { lazy } from "react";

import AuthGuard from "./AuthGuard";

const MainLayout = lazy(() => import("../layouts/MainLayout"));
const Structure = lazy(() => import("../pages/Structure"));
const GestionComptes = lazy(() =>
  import("../pages/admin/accounts/AccountsList")
);
const GestionDistributeur = lazy(() => import("../pages/admin/distributeurs"));
const CommandesDetails = lazy(() => import("../pages/public/CommandesDetails"));
const Features = lazy(() => import("../pages/public/Features"));
const GestionCommandes = lazy(() =>
  import("../pages/super-admin/GestionCommandes")
);

const requiredRole = "admin";

const AdminRoutes = {
  path: "admin",
  element: <MainLayout />,
  children: [
    {
      path: "dashboard",
      element: (
        <AuthGuard requiredRole={requiredRole}>
          <Features />
        </AuthGuard>
      ),
    },
    {
      path: "gestion-comptes",
      element: (
        <AuthGuard requiredRole={requiredRole}>
          <GestionComptes />
        </AuthGuard>
      ),
    },
    {
      path: "gestion-distributeurs",
      element: (
        <AuthGuard requiredRole={requiredRole}>
          <GestionDistributeur />
        </AuthGuard>
      ),
    },
    {
      path: "gestion-sites",
      element: (
        <AuthGuard requiredRole={requiredRole}>
          <Structure />
        </AuthGuard>
      ),
    },
    {
      path: "commandes",
      element: (
        <AuthGuard requiredRole={requiredRole}>
          <GestionCommandes />
        </AuthGuard>
      ),
    },
    {
      path: "commandes/:ref",
      element: (
        <AuthGuard requiredRole={requiredRole}>
          <CommandesDetails />
        </AuthGuard>
      ),
    },
    // {
    //   path: "gestion-produits",
    //   element: (
    //     <AuthGuard requiredRole={requiredRole}>
    //       <ProductList />
    //     </AuthGuard>
    //   ),
    // },
  ],
};

export default AdminRoutes;
