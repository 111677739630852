import React, { Component } from "react";
import errorImage from "../../assets/images/error-404.svg";
import { Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
      showDetails: false,
    };
  }

  static getDerivedStateFromError(error) {
    // Met à jour l'état pour indiquer que le composant enfant a généré une erreur.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Capture et log l'erreur dans la console.
    console.error(error, errorInfo);
    this.setState({ error, errorInfo });
  }

  handleReset = () => {
    // Réinitialise l'état de l'erreur.
    this.setState({ hasError: false, showDetails: false });
  };

  toggleDetails = () => {
    // Bascule la visibilité des détails de l'erreur.
    this.setState((prevState) => ({ showDetails: !prevState.showDetails }));
  };

  render() {
    // Vérifie si l'application est en mode production.
    const isProduction = process.env.NODE_ENV === "production";

    if (this.state.hasError && isProduction) {
      return (
        <div className="w-full h-full flex flex-col">
          <div className="w-full h-full flex flex-col items-center justify-center relative">
            <h1 className="pb-[42px]">Une erreur est survenue...</h1>
            <img
              src={errorImage}
              alt="Une erreur est survenue..."
              width={850}
              style={{ transform: "rotate(-10deg)" }}
            />
            <Typography
              sx={{
                mt: "16px",
                pb: "24px",
                maxWidth: "640px",
                textAlign: "center",
              }}
            >
              Nous sommes désolés, mais il semble qu'il y ait eu un problème
              lors du chargement de la page que vous demandiez. Cela pourrait
              être dû à une erreur temporaire sur notre site ou à un lien
              incorrect
            </Typography>
            <Button
              to="/dashboard"
              component={Link}
              variant="contained"
              color="secondary"
              className="scania-button"
              sx={{ mr: 1 }}
              onClick={this.handleReset}
            >
              RETOUR SUR MON DASHBOARD
            </Button>
            <button
              onClick={this.toggleDetails}
              className="absolute bottom-0 right-0 opacity-0"
            >
              {this.state.showDetails ? "</>" : "</>"}
            </button>
          </div>
          {this.state.showDetails && (
            <div className="bg-[#4d0000] h-[300px] w-full p-[20px]">
              <p className="text-white">
                {this.state.error && this.state.error.toString()}
              </p>
              <p className="text-white">
                {this.state.errorInfo && this.state.errorInfo.componentStack}
              </p>
            </div>
          )}
        </div>
      );
    }

    // Quand il n'y a pas d'erreur, rendre les composants enfants normalement.
    return this.props.children;
  }
}

export default ErrorBoundary;
