import { lazy } from "react";
import AuthGuard from "./AuthGuard";

const MainLayout = lazy(() => import("../layouts/MainLayout"));

const Catalog = lazy(() => import("../pages/public/Catalog"));
const Commandes = lazy(() => import("../pages/public/Commandes"));
const Profil = lazy(() => import("../pages/public/Profil"));
const Features = lazy(() => import("../pages/public/Features"));
const Product = lazy(() => import("../pages/public/Product"));
const Panier = lazy(() => import("../pages/public/Panier"));
const Tickets = lazy(() => import("../pages/public/tickets"));
const CommandesDetails = lazy(() => import("../pages/public/CommandesDetails"));
const TicketChat = lazy(() => import("../pages/public/tickets/TicketChat"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const UserProfile = lazy(() => import("../pages/public/UserProfile"));
const CommercialCampaignManager = lazy(() =>
  import("../pages/public/campaigns/CommercialCampaignManager")
);
const EventManager = lazy(() =>
  import("../pages/public/campaigns/EventManager")
);
const CommercialManager = lazy(() =>
  import("../pages/public/campaigns/commerciale/CommercialManager")
);
const CommercialProduct = lazy(() =>
  import("../pages/public/campaigns/commerciale/CommercialProduct")
);
const EvenementielleManager = lazy(() =>
  import("../pages/public/campaigns/evenementielle/EvenementielleManager")
);
const EvenementielleProduct = lazy(() =>
  import("../pages/public/campaigns/evenementielle/EvenementielleProduct")
);
const Faq = lazy(() => import("../pages/public/Faq"));

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      index: true,
      element: (
        <AuthGuard>
          <Dashboard />
        </AuthGuard>
      ),
    },
    {
      path: "dashboard",
      element: (
        <AuthGuard>
          <Dashboard />
        </AuthGuard>
      ),
    },
    {
      path: "catalogue",
      element: (
        <AuthGuard>
          <Catalog />
        </AuthGuard>
      ),
    },
    {
      path: "produit/:type/:slug",
      element: (
        <AuthGuard>
          <Product />
        </AuthGuard>
      ),
    },
    {
      path: "commandes",
      element: (
        <AuthGuard>
          <Commandes />
        </AuthGuard>
      ),
    },
    {
      path: "commandes/:ref",
      element: (
        <AuthGuard>
          <CommandesDetails />
        </AuthGuard>
      ),
    },
    {
      path: "panier",
      element: (
        <AuthGuard>
          <Panier />
        </AuthGuard>
      ),
    },
    {
      path: "features",
      element: (
        <AuthGuard>
          <Features />
        </AuthGuard>
      ),
    },
    {
      path: "profil",
      element: (
        <AuthGuard>
          <Profil />
        </AuthGuard>
      ),
    },
    {
      path: "profil/:id",
      element: (
        <AuthGuard>
          <UserProfile />
        </AuthGuard>
      ),
    },
    {
      path: "tickets",
      element: (
        <AuthGuard>
          <Tickets />
        </AuthGuard>
      ),
    },
    {
      path: "tickets/:ref",
      element: (
        <AuthGuard>
          <TicketChat />
        </AuthGuard>
      ),
    },
    // {
    //   path: "factures",
    //   element: <Factures />,
    // },
    {
      path: "faq",
      element: (
        <AuthGuard>
          <Faq />
        </AuthGuard>
      ),
    },

    // URLs UTILITAIRES
    {
      path: "campagnes",
      element: <CommercialCampaignManager />,
    },

    {
      path: "scenarios-de-campagne",
      element: <EventManager />,
    },
    {
      path: "scenarios-de-campagne/campagne-commerciale",
      element: <CommercialManager />,
    },
    {
      path: "scenarios-de-campagne/campagne-commerciale/:slug",
      element: <CommercialProduct />,
    },
    {
      path: "scenarios-de-campagne/campagne-evenementielle",
      element: <EvenementielleManager />,
    },
    {
      path: "scenarios-de-campagne/campagne-evenementielle/:slug",
      element: <EvenementielleProduct />,
    },
    // {
    //   path: "components",
    //   element: <Composants />,
    // },
    // {
    //   path: "components/mui",
    //   element: <ComponentsHub />,
    // },
    // {
    //   path: "affiches",
    //   element: <AffichesForm />,
    // },
    // {
    //   path: "brochures",
    //   element: <BrochuresForm />,
    // },
    // {
    //   path: "pub",
    //   element: <InsertPubForm />,
    // },
    // {
    //   path: "sms",
    //   element: <SmsForm />,
    // },
    // {
    //   path: "rollup",
    //   element: <RollUpForm />,
    // },
    // {
    //   path: "livreblanc",
    //   element: <LivresBlancForm />,
    // },
    // {
    //   path: "pochette",
    //   element: <PochettesForm />,
    // },
    // {
    //   path: "taw",
    //   element: <TawsForm />,
    // },
    // {
    //   path: "emailing",
    //   element: <EmailingsForm />,
    // },
    // {
    //   path: "cartemessage",
    //   element: <CartesMessageForm />,
    // },
    // {
    //   path: "brochureservice",
    //   element: <BrochureServiceForm />,
    // },
    // {
    //   path: "structure",
    //   element: <Structure />,
    // },
  ],
};

export default MainRoutes;
