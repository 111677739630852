// color design tokens export
export const colorTokens = {
  primary: {
    50: "#ffffff",
    100: "#ffccd3",
    200: "#ff99a7",
    300: "#ff667a",
    400: "#ff334e",
    500: "#d6001d",
    600: "#cc001b",
    700: "#990014",
    800: "#66000e",
    900: "#330007",
  },
  secondary: {
    50: "#E7F1FE",
    100: "#cfe2fc",
    200: "#9fc6f9",
    300: "#6fa9f6",
    400: "#3f8df3",
    500: "#041e41",
    600: "#0c5ac0",
    700: "#094390",
    800: "#062d60",
    900: "#031630",
  },
  grey: {
    0: "#FFFFFF",
    10: "#F6F6F6",
    50: "#F0F0F0",
    100: "#E0E0E0",
    200: "#C2C2C2",
    300: "#A3A3A3",
    400: "#858585",
    500: "#666666",
    600: "#4D4D4D",
    700: "#333333",
    800: "#1A1A1A",
    900: "#0A0A0A",
    1000: "#000000",
  },
};

// mui theme settings
export const themeSettings = (mode) => {
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            // palette values for dark mode
            primary: {
              dark: colorTokens.primary[200],
              main: colorTokens.primary[500],
              light: colorTokens.primary[800],
            },
            secondary: {
              dark: colorTokens.secondary[200],
              main: colorTokens.secondary[500],
              light: colorTokens.secondary[800],
            },
            neutral: {
              dark: colorTokens.grey[100],
              main: colorTokens.grey[200],
              mediumMain: colorTokens.grey[300],
              medium: colorTokens.grey[400],
              light: colorTokens.grey[700],
            },
            background: {
              default: colorTokens.grey[900],
              alt: colorTokens.grey[800],
            },
          }
        : {
            // palette values for light mode
            primary: {
              dark: colorTokens.primary[700],
              main: colorTokens.primary[500],
              light: colorTokens.primary[50],
            },
            secondary: {
              dark: colorTokens.secondary[200],
              main: colorTokens.secondary[500],
              light: colorTokens.secondary[800],
            },
            neutral: {
              dark: colorTokens.grey[700],
              main: colorTokens.grey[500],
              mediumMain: colorTokens.grey[400],
              medium: colorTokens.grey[300],
              light: colorTokens.grey[50],
            },
            background: {
              default: colorTokens.grey[10],
              alt: colorTokens.grey[0],
            },
          }),
    },
    typography: {
      // fontFamily: ["Rubik", "sans-serif"].join(","),
      // fontSize: 13,
      h1: {
        fontFamily: "Scania Sans Headline",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal",
      },
      h2: {
        fontFamily: "Scania Sans Headline",
        fontSize: "20px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal",
      },
      h3: {
        fontFamily: "Scania Sans Headline",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "normal",
      },
    },
    shape: {
      borderRadius: 10,
    },
    components: {
      MuiAlert: {
        styleOverrides: {
          root: {
            // Ajoutez ici les styles personnalisés pour le composant Alert
            backgroundColor: colorTokens.secondary[50], // Exemple de couleur de fond
            color: colorTokens.secondary[500], // Exemple de couleur du texte
          },
          standardInfo: {
            // Ajoutez ici les styles spécifiques à la variante 'info'
            fontFamily: "scania_officebold",
            borderWidth: 2,
            borderStyle: "solid",
            borderColor: colorTokens.secondary[300],
            backgroundColor: colorTokens.secondary[50], // Exemple de couleur de fond pour la variante 'info'
            color: colorTokens.secondary[500], // Exemple de couleur du texte
          },
        },
      },
    },
  };
};
